/*
-----------------------------------------------------------------------
	Booking CSS
-----------------------------------------------------------------------
*/

.booking-area {
  padding: 25px 0 95px;
  @media #{$md-device} {
    padding: 75px 0;
  }
  @media #{$sm-device} {
    padding: 55px 0;
  }
  .title {
    margin-bottom: 40px;
    @media #{$lg-device} {
      margin-bottom: 20px;
    }
  }
  p {
    font-size: 18px;
    letter-spacing: -.16px;
    line-height: 30px;
    margin-bottom: 0;
    @media #{$sm-device} {
      font-size: 16px;
      br {
        display: none;
      }
    }
    @media #{$xs-device} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  iframe {
    display: block;
    max-width: 320px;
    margin: 0 auto;
  }
}
