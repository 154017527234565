/*
-----------------------------------------------------------------------
	Video CSS
-----------------------------------------------------------------------
*/

.video-area {
  padding: 95px 0 0px;
  @media #{$md-device} {
    padding: 75px 0 0;
  }
  @media #{$sm-device} {
    padding: 55px 0 0;
  }
  .iframe-video {
      border: 0;
      width: 100%;
      height: 400px;
      @media #{$sm-device} {
        height: 200px;
      }
  }
  .title {
    margin-bottom: 40px;
    @media #{$lg-device} {
      margin-bottom: 20px;
    }
  }
  p {
    font-size: 18px;
    letter-spacing: -.16px;
    line-height: 30px;
    margin-bottom: 0;
    @media #{$sm-device} {
      font-size: 16px;
      br {
        display: none;
      }
    }
    @media #{$xs-device} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  ul {
    font-size: 18px;
    letter-spacing: -.16px;
    line-height: 30px;
    margin-bottom: 0;
    @media #{$xs-device} {
      font-size: 16px;
    }
    li {
      margin-bottom: 0;
      a {
        color: $body-color;
        &:hover {
          color: $black;
        }
      }
    }
  }
}
